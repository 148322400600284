import * as React from 'react';
import DefaultPage from '../../components/layouts/DefaultPage';
import Headline from '../../components/Headline/Headline';
// @ts-ignore
import * as styles from './welcome.module.css';
import CustomButton from '../../components/CustomButton/CustomButton';
import {
    getAuthUser,
    logout, setAuthUser
} from '../../helpers/user';
import {apiPostAuthenticated} from '../../api/api';
import {fetchUser} from '../../api/user';
import {Form, Formik} from 'formik';
import CheckboxGroup from '../../components/formElements/CheckboxGroup/CheckboxGroup';
import FormSubmit from '../../components/formElements/FormSubmit/FormSubmit';
import {useEffect, useState} from 'react';
import TextArea from '../../components/formElements/TextArea/TextArea';
import {votingCategories} from '../../components/VoteBar/VoteBar';
import {fetchTopApplications} from '../../api/application';
import {connect} from 'react-redux';
import {Link, navigate} from 'gatsby';

const JuryFinished = ({juryVotings,}) => {
    const [user, setUser,] = useState(getAuthUser());
    const [applications, setApplications,] = useState([]);

    // user has not finished yes
    useEffect(
        () => {
            (async () => {
                const result = await fetchTopApplications();
                if (result.success) {
                    const topApplications = result.applications.map(application => {
                        return {
                            ...application,
                            votings: juryVotings.filter(v => {
                                return v.applicationUuid === application.uuid;
                            }),
                        };
                    });
                    setApplications(topApplications);
                    const numberOfFinsishedVotings = topApplications.filter(a => {
                        return a.votings.length === votingCategories.length;
                    }).length;
                    if (!(numberOfFinsishedVotings === topApplications.length && 0 < topApplications.length)){
                        alert('Sie müssen alle Votings vervollständigen, bevor Sie final abschicken können');
                        navigate('/jury');
                    }
                }
            })();
        },
        []
    );

    if (!user?.declared_independence) {
        return (
            <DefaultPage phase={process.env.GATSBY_PHASE}>
                {/* Container */}
                <div className={`grid-10--desktop grid-8--tablet grid-offset-1--desktop ${styles.welcome__container}`}>
                    {/* Headline */}
                    <Headline headline="Voting abschicken"/>
                    <p>
                        Bitte füllen Sie folgendes Formular aus, um Ihr Voting final abzuschicken:
                    </p>

                    <Formik
                        validate={
                            () => {
                                const errors = {} as any;
                                // @ts-ignore
                                if (!document.querySelector('[name="independent[finalize]"]').checked) {
                                    errors.independent = 'Bitte bestätigen Sie diese Angabe';
                                }
                                return errors;
                            }}
                        initialValues={{
                            independent: null,
                            associated: null,
                        }}
                        onSubmit={async () => {
                            const result = await apiPostAuthenticated('/jury/finalize', {
                                associated: document.querySelector('[name="associated"]').value,
                            });
                            if (result.success) {
                                const user = await fetchUser();
                                if (user) {
                                    setAuthUser(user);
                                    setUser(user);
                                }
                            }
                        }}>
                        {() => {
                            return (
                                <Form>
                                    <CheckboxGroup
                                        name="independent"
                                        options={[{
                                            'value': 'finalize',
                                            label: 'Hiermit bestätige ich, dass ich meine Bewertung unabhängig getroffen habe. (Pflichtfeld)',
                                        },]}
                                        onChange={() => {}} />

                                    <TextArea
                                        label="Ich stehe im Verhältnis zu folgendem Bewerber/folgenden Bewerbern:"
                                        placeholder="Bitte um kurze Beschreibung Ihrer Verbindung zum jeweiligen Bewerber. "
                                        name="associated"
                                        onBlur={() => {}}
                                    />

                                    <FormSubmit
                                        label={'Absenden'}
                                    />
                                </Form>);
                        }}
                    </Formik>
                </div>
            </DefaultPage>
        );
    }

    let lastPosition = 0;
    let lastCount = 0;
    return (
        <DefaultPage phase={process.env.GATSBY_PHASE}>
            {/* Container */}
            <div
                className={`grid-8--desktop grid-offset-2--desktop ${styles.welcome__container}`}
                style={{textAlign: 'center',}}>
                {/* Headline */}
                <Headline headline="Vielen Dank"/>
                <p>
                    Wir freuen uns über Ihre Teilnahme am Voting des FOCUS Innovationspreis!
                    Ihre Bewertung wurde erfolgreich übermittelt.
                </p>

                <h2>So haben Sie gewertet</h2>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Platz</th>
                            <th>Innovation</th>
                            <th>Punkte</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            applications.sort((a,b) => {
                                return b.votings.map(v => {
                                    return v.voting;
                                }).reduce((a, b) => {
                                    return a + b;
                                }, 0) - a.votings.map(v => {
                                    return v.voting;
                                }).reduce((a, b) => {
                                    return a + b;
                                }, 0);
                            }).map((application, index) => {
                                const count = application.votings.map(v => {
                                    return v.voting;
                                }).reduce((a, b) => {
                                    return a + b;
                                }, 0);
                                if (lastCount !== count) {
                                    lastPosition = index + 1;
                                }
                                lastCount = count;
                                return <tr>
                                    <td>{lastPosition}</td>
                                    <td>
                                        <Link to={`/jury/detail#${application.uuid}`}>
                                            {application.data.innovationName}
                                        </Link>
                                    </td>
                                    <td>{count}</td>
                                </tr>;
                            })
                        }
                    </tbody>
                </table>


                {/* Button */}
                <div className={styles.welcome__button}>
                    <CustomButton
                        label="Logout"
                        onClick={logout}/>
                </div>
            </div>
        </DefaultPage>
    );
};

const mapStateToProps = ({
    juryVotings,
}) => {
    return {
        juryVotings,
    };
};

export default connect(mapStateToProps, null)(JuryFinished);
